import React, { ReactElement, useMemo } from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { CompanyInterface } from '../../../models/companyInterface';
import styles from './companyBox.module.css';
import config from '../../../config';
import LogoBoxDisplay from '../../../components/logoBoxDisplay/logoBoxDisplay';
import { LocalStorageEnum } from '../../../consts/LocalStorageEnum';

const CompanyBox = ({ company }: { company: CompanyInterface }): ReactElement => {
  const localStorageFileToken = localStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const sessionStorageFileToken = sessionStorage.getItem(LocalStorageEnum.FILE_ACCESS_TOKEN);
  const companyRoute = useMemo((): string => {
    return config.routes.mainEntity.view.replace(':id', String(company.id));
  }, [company.id]);
  const companyLogo = `${config.fileViewBasePath.replace(
    ':assetId',
    company?.companyLogo?.fileId as string,
  )}?token=${localStorageFileToken ?? sessionStorageFileToken}`;
  const logo = company.companyLogo?.fileId != null ? companyLogo : config.logo.logoPlaceholder;

  return (
    <Link
      to={companyRoute}
      className={cx([styles.root, config.features.useProfilePic && styles.profilePic])}
    >
      <LogoBoxDisplay>
        <img src={logo} alt={company.companyName} className={styles.logo} />
      </LogoBoxDisplay>
      <h3 className={styles.name}>{company.companyName}</h3>
      <div className={styles.location}>
        <EnvironmentOutlined /> <span className={styles.country}>{company.country}</span>
      </div>
    </Link>
  );
};

export default CompanyBox;
